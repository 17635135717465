import React from 'react';

import OSButton from "../Button";
import DropdownButton from "../Button/Dropdown";
import {useNavigate} from "react-router-dom";

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import './style.scss';

/**
 * A simple content component.
 *
 * @param {string} illustration - The illustation to display inside.
 * @param {string} text - The text to display
 * @param cta - Whether to add a CTA inside
 */

const EmptyContent = ({illustration, text, cta, dropdown, page, cgpUser}) => {
  const navigate = useNavigate();
  return (
    <div className="empty-content-container">
      <div className="svg-illustration">{illustration}</div>
			<p className="os-body2" style={{textAlign: page !== 'engagement' && "center"}} dangerouslySetInnerHTML={{ __html: text }}/>
			<div className={ page !== 'engagement' ? "empty-content-btn" : "engagement_btns"}>
        {cta && (
					<div className="os-flex gap-24 empty-content-btns">
						<OSButton link={cta.link} title={cta.title} variant={cta.variant} size={cta.size} onClick={cta.onClick} fit />
						{page === 'engagement' && <OSButton link={`mailto:${cgpUser.sales !== 'un conseiller' ? cgpUser.sales : 'souscriptions'}@openstone.com`} title={`Contacter ${cgpUser.sales}`} variant="no_border" size="large" fit icon={<MailOutlineIcon />}/>}
					</div>
				)}
        {dropdown && <DropdownButton
          title={dropdown.title}
          variant={dropdown.variant}
          size="small"
					fit
          items={dropdown.items.map(product => ({
            label: product.name,
            onClick: () => navigate(`${window.location.pathname}/investissement/${product.category_slug}/${product.slug}/finalise`, {state: dropdown.urlState})
          }))}
        />}
      </div>
    </div>
  );
};

export default EmptyContent;
