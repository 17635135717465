import React, { useEffect, useState } from 'react';
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";
import { useLocation, useParams } from "react-router";
import OsCard from "../../../../components/card/basic";
import { useNavigate } from "react-router-dom";

import { ChevronRight } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import UserTransactionNetwork from "../../../../config/Network/UserTransactionNetwork.ts";
import getBadge from "../../../../components/Badge/getBadge";
import renderChart from "../../../../components/User/chart";
import RenderTable from "../../../../components/User/renderTable";
import { ReactComponent as NoDocs } from "../../../../assets/Illustrations/no-documents.svg";
import EmptyContent from "../../../../components/empty-content/EmptyContent";
import DropdownButton from "../../../../components/Button/Dropdown";
import ProductInvestment from "../../../../components/Product/Investment";
import OSButton from "../../../../components/Button";
import DescriptionIcon from "@mui/icons-material/Description";
import FullPageLoader from "../../../../components/Loaders/FullPage/profile";

import { checkDocumentType } from "../../../../Helpers/GeneralHelper";

import "./style.scss";
import EditIcon from "@mui/icons-material/Edit";

const ClientView = () => {
	const { clientId } = useParams();
	const [client, setClient] = useState(null);
	const [transactions, setTransactions] = useState([]);
	const [loading, setLoading] = useState(true);
	const { state } = useLocation();
	const navigate = useNavigate();
	const [selectedOpportunity] = useState('all');
	
	async function fetchClient() {
		try {
			const response = await CgpNetwork.getClient(clientId, state.listableType);
			setClient(response);
		} catch (error) {
			console.error("Error fetching client:", error);
		}
	}
	
	async function fetchMyTransactions() {
		try {
			const apiTransactions = await UserTransactionNetwork.myTransactions(state.listableType === 'User', clientId);
			setTransactions(apiTransactions);
		} catch (error) {
			console.error("Error fetching transactions:", error);
		}
	}
	
	useEffect(() => {
		if (state) {
			Promise.all([fetchClient(), fetchMyTransactions()]).then(() => {
				setLoading(false);
			});
		} else {
			window.location.replace('/cgp/clients');
		}
	}, []);
	
	if (loading) {
		return (
			<div className="clients-container">
				<FullPageLoader />
			</div>
		)
	}
	
	const clientInfos = () => {
		if (state.listableType === 'User') {
			return (
				<div className="os-flex client-infos">
					<div className="os-flex__column">
						<p className="os-body2 profile-info-container"><strong>Nom:</strong> {client.name}</p>
						<p className="os-body2 profile-info-container"><strong>Date de naissance:</strong> {client.date_of_birth}</p>
						<p className="os-body2 profile-info-container"><strong>Adresse:</strong> {client.address_line_1}</p>
						<p className="os-body2 profile-info-container"><strong>Ville:</strong> {client.address_city}</p>
						<p className="os-body2 profile-info-container"><strong>Code postal:</strong> {client.address_zip_code}</p>
					</div>
					<div>
						<div className="os-flex__column gap-8 profile-badges-container">
							<p className="os-body2"><strong>Statut de vérification:</strong> {getBadge(client.kyc_status, 'kyc_status')}</p>
							{client.profile_score > 0 && (
								<p className="os-body2">
									  <strong>Profil de risque:</strong>
										{getBadge(`${client.profile_score}`, 'score')}
								</p>
							)}
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className="os-flex client-infos">
					<div className="os-flex__column">
						<p className="os-body2"><strong>Dénomination légale:</strong> {client.name}</p>
						<p className="os-body2"><strong>Statut juridique:</strong> {client.business_type}</p>
						<p className="os-body2"><strong>Siren:</strong> {client.siren}</p>
						<p className="os-body2"><strong>Adresse:</strong> {client.address}</p>
						<p className="os-body2"><strong>Ville:</strong> {client.city}</p>
						<p className="os-body2"><strong>Code postal:</strong> {client.zip_code}</p>
					</div>
					<div>
						<div className="os-flex__column gap-8 profile-badges-container">
							<p className="os-body2"><strong>Statut de vérification:</strong> {getBadge(client.kyc_status, 'kyc_status')}</p>
							{client.profile_score > 0 && (
								<p className="os-body2">
									<strong>Profil de risque:</strong>
									{getBadge(`${client.profile_score}`, 'score')}
								</p>
							)}
						</div>
					</div>
				</div>
			)
		}
	}
	
	
	const clientUploads = () => {
		if (client?.document_uploads?.length > 0) {
			return (
				<div className="os-flex client-uploads gap-16">
					{client.document_uploads.map((up, index) => (
						<a className="product-document" href={up.url} target="_blank" rel="noreferrer" key={`${up.name}-${index}`}>
							<div className="pdf-logo-container">
								<DescriptionIcon />
							</div>
							<div className="os-flex product-document-name-container">
								<div>
									<p className="product-document-name os-body2">{checkDocumentType(up.document_type)}</p>
									<span className={up.processed ? "success" : "warning"}>{up.processed ? "Vérifié" : "En cours de vérification"}</span>
								</div>
								<ChevronRight />
							</div>
						</a>
					))}
				</div>
			);
		} else {
			return <EmptyContent illustration={<NoDocs />} text="Pas de justificatifs pour ce client" />;
		}
	}
	
	const clientDocuments = () => {
		if (client?.documents?.length > 0) {
			return (
				<OsCard
					title="Documents de souscription"
					onClick={() => navigate(`${window.location.pathname}/documents`, { state: { clientId, clientType: state.listableType } })}
					linkTitle="Voir tous les documents"
					conditionForLink={client.documents.length > 6}
					badges={[
						{ number: client.documents.filter(doc => doc.status === 'pending').length, text: "En attente de signature", status: "pending" },
						{ number: client.documents.filter(doc => doc.status === 'signed').length, text: "Signés", status: "signed" },
						{ number: client.documents.filter(doc => doc.status === 'expired').length, text: "Expirés", status: "expired" }
					]}
				>
					<div className="os-flex client-documents">
						{client.documents.slice(0, 6).map(doc => (
							<a className="product-document" href={doc.file?.url} target="_blank" rel="noreferrer" key={doc.title}>
								<div className="pdf-logo-container">
									<DescriptionIcon />
								</div>
								<div className="os-flex product-document-name-container">
									<div>
										<p className="product-document-name os-body2">{doc.title}</p>
										<span className={doc.status === 'signed' ? "success" : "warning"}>{doc.status === "signed" ? `Signé le ${doc.signed_at}` : "Signature en attente"}</span>
									</div>
									<ChevronRight />
								</div>
							</a>
						))}
					</div>
				</OsCard>
			);
		} else {
			return (
				<OsCard
					title="Documents"
					onClick={() => navigate(`${window.location.pathname}/documents`, { state: { clientId, clientType: state.listableType } })}
					linkTitle="Voir tous les documents"
					conditionForLink={client?.documents?.length > 6}
				>
					<EmptyContent illustration={<NoDocs />} text="Aucun documents à afficher" />
				</OsCard>
			);
		}
	}
	
	const renderInvestmentTable = () => {
		if (selectedOpportunity === 'all') {
			return RenderTable(loading, transactions, 'cgp', client, state?.listableType, 'cgpClient');
		} else {
			return (
				<div className="cgp-investment-table">
					<ProductInvestment clientViewProduct={selectedOpportunity} clientType={state?.listableType} page="clientView" />
				</div>
			);
		}
	}
	
	return (
		<div className="clients-container">
			<OSButton title="Revenir à la liste des clients" fit size="medium" variant="primary-full" link="/cgp/clients" icon={<ArrowBackIcon />} />
			<div className="os-flex clients-top-container">
				<div className="os-flex clients-top-container-btns">
					<h5 className="os-h5">Vue client - {client?.name}</h5>
				</div>
				<div className="os-flex">
					<DropdownButton
						title="Réaliser une souscription"
						variant="primary"
						size="large"
						items={client?.available_products?.map(product => ({
							label: product.name,
							onClick: () => navigate(`${window.location.pathname}/investissement/${product.category_slug}/${product.slug}/finalise`, { state: { clientId, clientType: state.listableType } })
						}))}
						disabled={loading}
					/>
				</div>
			</div>
			<div className="os-flex client-flex-container">
				<div className="client-infos-container">
					<OsCard title="Informations principales"
									link={state?.listableType !== 'Company' ? `${window.location.pathname}/personne-physique/edition` : `${window.location.pathname}/personne-morale/edition`}
									linkTitle="Éditer"
									conditionForLink={true}
									icon={<EditIcon />}
					>
						{clientInfos()}
					</OsCard>
				</div>
				<div className="clients-uploads-container">
					{renderChart(loading, client, 'cgp')}
				</div>
			</div>
			<div style={{ marginBottom: 32 }}>
				<OsCard title="Justificatifs"
								onClick={() => {
									if (state?.listableType === 'Company') {
										localStorage.setItem('CompanySurveyStep', '4');
										navigate(`${window.location.pathname}/personne-morale/edition`)
									} else {
										localStorage.setItem('UserSurveyStep', '3');
										navigate(`${window.location.pathname}/personne-physique/edition`)
									}
								}}
								linkTitle="Ajouter des justificatifs"
								conditionForLink={true}>
					{clientUploads()}
				</OsCard>
			</div>
			<OsCard title="Synthèse des investissements">
					{renderInvestmentTable()}
			</OsCard>
			<div style={{marginTop: 32}}>
				{clientDocuments()}
			</div>
		</div>
	);
};

export default ClientView;
