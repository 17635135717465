import Network from './Network.ts';

class ProductNetwork extends Network {
  async getProducts(category: string) {
    return await this.get('/products', { category_name: null });
  }

  async getProduct(productId: number, recordId: string | null, recordType: string | null) {
    const queryParams = new URLSearchParams();

    if (recordId) {
      queryParams.append('record_id', recordId);
    }

    if (recordType) {
      queryParams.append('record_type', recordType);
    }

    const queryString = queryParams.toString();

    // Construct the URL with query parameters
    const url = `/products/${productId}${queryString ? `?${queryString}` : ''}`;

    try {
      // Log the final URL for debugging
      console.log('Fetching product with URL:', url);

      // Make the API call
      return await this.get(url);
    } catch (error) {
      console.error('Error fetching product:', error);
      throw error;
    }
  }


  async createInterest(productId: string, interestedType: string) {
    return await this.post(`/interests/create`, {
      interestable_type: 'Product',
      interestable_id: productId,
      record_type: interestedType
    });
  }

  async getPublicProduct(productId) {
    return await this.get(`/products/${productId}/public`);
  }
}

export default new ProductNetwork();
