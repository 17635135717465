import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {CircularProgress} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StepsContainer from "../StepsContainer";
import MoneyHelper from "../../../../../Helpers/MoneyHelper";
import SignatureNetwork from "../../../../../config/Network/SignatureNetwork.ts";
import DocumentNetwork from "../../../../../config/Network/DocumentNetwork.ts";
import Pdf from "../../../../../components/Document/pdf";
import BannerLoader from "../../../../../components/Loaders/Banner";
import OSButton from "../../../../../components/Button";

import UserTransactionNetwork from "../../../../../config/Network/UserTransactionNetwork.ts";
import CompanyTransactionNetwork from "../../../../../config/Network/CompanyTransactionNetwork.ts";
import CgpNetwork from "../../../../../config/Network/CgpNetwork.ts";

import DepositForm from "../../../../../Forms/Opportunities/Deposit/DepositForm";

import './style.scss';

const Signature = ({product, nextStep, storageDefaultProfile, page, cgp, clientId, clientType, minimumInvestment}) => {
  const defaultProfile = cgp ? (clientType !== 'Company') : storageDefaultProfile
  const [amount, setAmount] = useState(localStorage.getItem('InvestmentAmount') || null);
  const [cgpEntryFees, setCgpEntryFees] = useState(localStorage.getItem('CgpEntryFees') || null);
  const [fundsCallChoice] = useState(localStorage.getItem('FundsCall') || false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(page !== 'withdrawal');
  const [pdfLoading, setPdfLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [listing, setListing] = useState(true);
  const [documentToSign, setDocumentToSign] = useState('');
  const [documentToSignTitle, setDocumentToSignTitle] = useState('');
  const [toSignDocs, setToSignDocs] = useState([]);
  const [signedDocs, setSignedDocs] = useState([]);
  const [userDocuments, setUserDocuments] = useState([]);
  const [canContinue, setCanContinue] = useState(false);
  const [validate, setValidate] = useState(true);
  const [infoMsg, setInfoMsg] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const localStoragePromoCode = localStorage.getItem('promoCode');
  const [withdrawDocId, setWithdrawDocId] = useState(null);
  const typeOfBs = 'bs';
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const navigate = useNavigate();

  function checkGender() {
    if (documentToSignTitle === "Fiche d'information client" || documentToSignTitle === "Lettre de mission") {
      return `${documentToSignTitle} signée !`
    } else {
      return `${documentToSignTitle} signé !`
    }
  }
	
	const getUserDocuments = async () => {
		setPdfLoading(true);
		
		const response = (defaultProfile || clientType === 'User')
			? await DocumentNetwork.get_documents(product.id, clientId)
			: await DocumentNetwork.get_company_documents(product.id, clientId);
		
		if (response) {
			// Collect all signed document types
			const signedDocumentTypes = response
				.filter(doc => doc.check_document_state && doc.status === "signed")
				.map(doc => doc.document_type);
			
			// Update signedDocs state once with the collected types
			// setSignedDocs(signedDocumentTypes);
			setSignedDocs(prevSignedDocs => Array.from(new Set([...prevSignedDocs, ...signedDocumentTypes])));
			// Update userDocuments state
			setUserDocuments(response);
			setPdfLoading(false);
			setLoading(false);
		} else {
			setPdfLoading(true);
		}
	};

  const fillPdf = async(type) => {
    setPdfLoading(true);
    const response = await SignatureNetwork.fill_values(type, parseInt(product.id), {amount: (page === 'withdrawal' ? localStorage.getItem('@amount') : amount), cgp_entry_fees: cgp && localStorage.getItem('CgpEntryFees'), cgp_management_fees: cgp && localStorage.getItem('CgpManagementFees')}, defaultProfile ? 'user' : 'company', clientId);
    if (response.pdf_url) {
      setPdfUrl(response.pdf_url);
      setPdfLoading(false);
    } else {
      setPdfLoading(true);
    }
  }

  function loadDocument(type, title) {
    if (signedDocs.includes(type)) {
      userDocuments.map(ud => ud.document_type === type && setPdfUrl(ud.file.url))
      setListing(false);
    } else {
      setListing(false);
      fillPdf(type);
    }
    setDocumentToSign(type);
    setDocumentToSignTitle(title);
  }
	
	useEffect(() => {
		console.log(signedDocs)
		console.log(transactionId)
	}, [listing, signedDocs, transactionId]);

  const signDocument = async () => {
    setSubmitting(true);
    const response = await SignatureNetwork.sign(documentToSign, parseInt(product.id), {title: documentToSignTitle, amount: page === 'withdrawal' ? localStorage.getItem('@amount') : amount, fundsCall: fundsCallChoice}, defaultProfile ? 'user' : 'company', localStoragePromoCode);
    if (response) {
      if (response.document_type === typeOfBs || response.document_type === (defaultProfile ? 'ra_pe' : 'ra_pe_pm')) {
        setSignedDocs(signedDocs => [...signedDocs, response.document_type])
        setTransactionId(response.transaction_id)
      } else if (page === 'withdrawal' && response.document_type === (defaultProfile ? 'br' : 'br_pm')) {
        setSignedDocs(signedDocs => [...signedDocs, response.document_type])
        setWithdrawDocId(response.id.toString());
      }
      setListing(true);
      toast.success(checkGender(), {
        toastId: `signedDocument-${documentToSign}`
      });
    } else {
      toast.error(`Un problème est survenu lors de la signature, si le problème persiste veuillez contacter nos équipes.`,{
        toastId: `error-signedDocument-${documentToSign}`
      })
    }
    setSubmitting(false);
  }

  async function sendToClient() {
    setSubmitting(true);
    const documentsToSend = product.name !== 'Livret P.' ? checkRelatedDocumentsArray() : relatedDocumentsLivretP;
    const unsignedDocuments = documentsToSend.filter(document => !document.signed);
		const valuesToSubmit = {
			amount: amount,
			cgp_entry_fees: cgp && localStorage.getItem('CgpEntryFees'),
			fundsCall: fundsCallChoice,
			cgp_management_fees: cgp && localStorage.getItem('CgpManagementFees')
		};
		
    const response = await CgpNetwork.sendDocumentToClient(clientId, clientType, unsignedDocuments, valuesToSubmit, product.id, searchParams.get('transaction_type'));
		
    if (response.status === 200) {
      toast.success(response.message, {
        toastId: `sentDocument`
      });
      nextStep(true);
      navigate(`${window.location.pathname}?documents_signature=sent`, {state: {clientId, clientType}});
    } else if (response.status === 401) {
      toast.error(response.message, {
        toastId: `unauthorized-sentDocument`
      });
    } else {
      toast.error(`Un problème est survenu lors de l'envoi, si le problème persiste veuillez contacter nos équipes.`,{
        toastId: `error-sentDocument`
      })
    }
    setSubmitting(false);
  }

  useEffect(() => {
   page !== 'withdrawal' && getUserDocuments();
  }, [listing]);

  useEffect(() => {
    validate &&
			setAmount(localStorage.getItem('InvestmentAmount'))
	    setCgpEntryFees(localStorage.getItem('CgpEntryFees'))
  }, [validate])

  const isDocumentSubmitting = () => {
    if (submitting) {
      return (
        <button disabled={submitting} className="sign-document-btn">
          <CircularProgress color="inherit" />
          Signature en cours
        </button>
      )
    } else {
      return <OSButton variant="primary" size="large" onClick={() => signDocument(documentToSign)} title="Signer le document" fit submitting={submitting} />
    }
  };

  const DocumentToSign = () => {
    return (
      <>
        <OSButton size="medium" variant="primary-full" onClick={() => setListing(true)} icon={<ArrowBackIcon />} title="Revenir aux documents" fit />
        {(pdfUrl === 'null' || pdfLoading) ? (
          <div className="pdf-loading">
            <CircularProgress color="inherit" />
            <p>Génération du document en cours, veuillez patienter...</p>
          </div>
        ) : (
          <div style={{marginTop: 24}}>
            <div id="scroll-wrapper">
							<iframe
								src={pdfUrl}
								className="document-frame"
								frameBorder="0"
								height={700}
							/>
						</div>

            {!cgp && (
              <div className="sign-document-btn-container">
                {!signedDocs.includes(documentToSign) && isDocumentSubmitting()}
              </div>
            )}
          </div>
        )}
      </>
    )
  };

  function updateDocsToSignList(array) {
    let docsList = [];
    array.map(rd => docsList.push(rd.type))
    setToSignDocs(docsList);
  }

  const relatedDocuments = [
    {title: "Fiche d'information client", type: defaultProfile ? 'fic' : 'fic_pm', signed: signedDocs.includes(defaultProfile ? 'fic' : 'fic_pm'), product_id: null},
    {title: "Lettre de mission", type: 'ldm', signed: signedDocs.includes('ldm'), product_id: null},
    {title: product.category_name === 'FIA' ? `Rapport d'activité Private equity` : `Rapport d'activité ${product.category_name}`, type: (defaultProfile ? 'ra_pe' : 'ra_pe_pm'), signed: (signedDocs.includes((defaultProfile ? 'ra_pe' : 'ra_pe_pm'))), product_id: product.id},
    {title: `Bulletin de souscription ${product.name}`, type: typeOfBs, signed: signedDocs.includes(typeOfBs), product_id: product.id},
  ];

  const sepaMandateDoc = {
    title: `Mandat de prélèvement SEPA`, type: 'sepa_mandate', signed: false, product_id: product.id
  }

  const relatedDocumentsLivretP = [
    {title: `Bulletin de souscription ${product.name}`, type: 'bs', signed: signedDocs.includes('bs')},
  ]

  const relatedDocumentsWithdrawalLivretP = [
    {title: "Bulletin de retrait Livret P.", type: defaultProfile ? 'br' : 'br_pm', signed: defaultProfile ? signedDocs.includes('br') : signedDocs.includes('br_pm')}
  ]

  const checkRelatedDocumentsArray = () => {
    if (cgp && searchParams.get('transaction_type') === 'sepaMandate') {
      const exists = relatedDocuments.some(doc => doc.type === sepaMandateDoc.type);
      if (!exists) {
        relatedDocuments.push(sepaMandateDoc);
      }
      return relatedDocuments
    } else {
      return relatedDocuments
    }
  }

  function checkRelatedDocuments() {
    if (page === 'withdrawal') {
      updateDocsToSignList(relatedDocumentsWithdrawalLivretP);
    } else {
      updateDocsToSignList(product.name !== 'Livret P.' ? relatedDocuments : relatedDocumentsLivretP);
    }
  }

  useEffect(() => {
    checkRelatedDocuments();
    const hasAllElems = toSignDocs.every(elem => signedDocs.includes(elem));
    signedDocs.length !== 0 && setCanContinue(hasAllElems)
  }, [signedDocs])


  function updateValidate(amount) {
    setValidate(true);
    setInfoMsg(false);
    toast.success('Le nouveau montant a bien été validé, vous pouvez continuer la signature des documents');
  }

  function checkIfValidate() {
    if (!validate) {
			return (
		      <div className="update-amount-container colored-container">
		        <DepositForm product={product} page="opportunityStep2" func={updateValidate} minimumInvestment={minimumInvestment} cgp={cgp}/>
		      </div>
				)
    } else {
      return (
        <div className="update-amount-container colored-container">
	        {cgp ?
		        <p className="amount-to-update-text os-body1">Montant total à régler par le client (Investissement + frais d’entrée) : <strong>{MoneyHelper.formatEuro(amount * ((cgpEntryFees / 100) + 1), true, 0, 0)}</strong></p>
		        : <p className="amount-to-update-text os-body1">{cgp ? "Le montant de la souscription est de" : 'Vous souhaitez investir'} {MoneyHelper.formatEuro(amount, true, 0, 0)}</p>
					}
            {/*<OSButton variant="no_border" size="small" onClick={() => setValidate(false)} title="Modifier le montant" fit icon={<EditIcon />}/>*/}
        </div>
      )
    }
  }
  function updateTransactionId() {
    transactionId !== undefined && setSearchParams({transaction_id: transactionId.toString()});
    product.name !== 'Livret P.' ? nextStep(true) : window.location.replace(`/opportunites/livret-p/deposit?transaction_id=${transactionId}&amount=${amount}`);
  }

  async function createWithdraw() {
    if (defaultProfile) {
      const responseWithdraw = await UserTransactionNetwork.withdraw(
        localStorage.getItem('@amount'),
        withdrawDocId,
        localStorage.getItem('@iban'),
        localStorage.getItem('@bic')
      );
      responseWithdraw && toast.success('La demande de retrait a bien été enregistrée');
    } else if (!defaultProfile) {
      const responseWithdraw = await CompanyTransactionNetwork.withdraw(
        localStorage.getItem('@amount'),
        withdrawDocId,
        localStorage.getItem('@iban'),
        localStorage.getItem('@bic')
      );
      responseWithdraw && toast.success('La demande de retrait a bien été enregistrée');
    }
    setWithdrawSuccess(true);
  }

  const DocumentsToDisplay = () => (
    <div className="finalise-documents-container">
      {withdrawSuccess ? (
        <>
          <h4 className="os-h4">Transfert programmé</h4>
          <p className="os-subtitle1 success">Votre demande de retrait a bien été pris en compte et arrivera dans les prochains jours.</p>
          <button className="sign-document-btn" onClick={() => window.location.replace('/overview')}>Revenir à mon portefeuille</button>
        </>
      ) :
        <>
          {infoMsg && <p className="info">Veuillez valider le nouveau montant d'investissement</p>}
          <Pdf page="signature"
               func={loadDocument}
               relatedDocuments={(page === 'withdrawal' ? relatedDocumentsWithdrawalLivretP : (product.name !== 'Livret P.' ? checkRelatedDocumentsArray() : relatedDocumentsLivretP))}
               canClick={validate}
               updateInfoMsg={setInfoMsg}
               cgp={cgp}
               cgpAction={sendToClient} submitting={submitting}
          />
          {(canContinue && signedDocs.length !== 0) && (
            <div className="sign-document-btn-container">
              {page === 'withdrawal' ?
                (
                  <button className="sign-document-btn" onClick={() => createWithdraw()}>Demander le retrait</button>
                ): (
                  <button className="sign-document-btn" onClick={() => updateTransactionId()}>Continuer et procéder au paiement</button>
                )}
            </div>
          )}
        </>}
    </div>
  )

  if (loading) {
    return <StepsContainer children={<BannerLoader />} product={product} minimumInvestment={minimumInvestment}/>
  } else if (!loading) {
    return <StepsContainer children={listing ? <DocumentsToDisplay /> : <DocumentToSign />} product={product} minimumInvestment={minimumInvestment}/>
  }
};

export default Signature;
