import React, {useState} from 'react';

import OSButton from "../../Button";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddAlertIcon from '@mui/icons-material/AddAlert';
import OsTooltip from "../../Tooltips";
import CheckIcon from '@mui/icons-material/Check';
import ProductNework from "../../../config/Network/ProductNework.ts";
import {toast} from "react-toastify";
import OsModal from "../../Modals";
import getBadge from "../../Badge/getBadge";
import MoneyHelper from "../../../Helpers/MoneyHelper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const OpportunityCard = ({opportunity, page, needRefresh, slider}) => {
  const [submitting, setSubmitting] = useState(false);
  const [showingPreview, setShowingPreview] = useState(false);
  const cgp = localStorage.getItem('CgpUserAuthToken');
  async function createInterest() {
    setSubmitting(true);
    const response = await ProductNework.createInterest(opportunity.id, cgp ? 'cgp' : 'user');
    if (response.status === 200 || response.status === "success") {
      toast.success(response.message,{
        toastId: 'createAlertSuccessToast'
      });
      showingPreview && setShowingPreview(false);
      needRefresh(true);
      setSubmitting(false);
    } else {
      toast.error(response.message,{
        toastId: 'createAlertErrorToast'
      });
      setSubmitting(false);
    }
  }

  function checkCardClasses() {
    let classes;
    if (page === "dashboard") {
      classes = `opportunity-card opportunity-card-slider ${slider && 'opportunity-card-slider'}`
    } else {
      classes = `opportunity-card ${slider && 'opportunity-card-slider'}`
    }
    return classes;
  }

  function renderButton() {
    if (opportunity.subscription_state === 'waiting_list' || opportunity.subscription_state === 'close') {
      if (opportunity.has_interest) {
        return <span className="success os-caption os-flex gap-8" style={{alignItems: 'center'}}><CheckIcon/>Vous êtes inscrit sur la liste d'attente</span>
      } else {
        return <OSButton onClick={() => createInterest()} title="Je m'inscris sur la liste d'attente" size="medium" variant="primary-full" submitting={submitting} icon={<AddAlertIcon />} fit />
      }
    } else if (opportunity.subscription_state === 'open' || opportunity.subscription_state === 'waiting_list_with_info') {
      return <OSButton link={`/opportunites/${opportunity.category_slug}/${opportunity.slug}`} title="Accéder au produit" size="medium" variant="primary-full" fit />
    } else if (opportunity.subscription_state === 'end') {
      return <p className="os-body2 info" children="La souscription pour ce produit est terminée" />
    }
  }

  function renderCardAction() {
    if (opportunity.subscription_state === 'open' || opportunity.subscription_state === 'waiting_list_with_info') {
      return window.location.replace(`/opportunites/${opportunity.category_slug}/${opportunity.slug}`);
    } else if (opportunity.subscription_state === 'waiting_list' || opportunity.subscription_state === 'close') {
      return setShowingPreview(true);
    }
  }

  return (
    <div className={checkCardClasses()} key={`${opportunity.name}-${opportunity.slug}`}>
      <div onClick={() => renderCardAction()} className="opportunity-card-img" style={{backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 100%), url(${opportunity?.vignette?.original})`}} />
      <div className="opportunity-card-top">
        <div className="os-flex gap-8 opportunity-card-top__labels">
          <p className="os-subtitle2">{opportunity.category_name}</p>
					<div className="opportunity-card-img-label">
						{opportunity.subscription_state !== 'end' && getBadge(opportunity.subscription_state === 'open' ? 'verified' : 'pending', 'kyc_status', opportunity.subscription_state === 'open' ? 'Souscription ouverte' : 'Souscription fermée')}
						{opportunity.subscription_state === 'end' && getBadge(opportunity.subscription_completed && 'verified', 'kyc_status', 'Souscription terminée', 'info')}
					</div>
        </div>
        <div className="has-interest">
          <h6 className="os-h6 opportunity-title">{opportunity.name}</h6>
        </div>
        <p dangerouslySetInnerHTML={{__html: opportunity.content}} className="opportunity-card-content os-body1" />
      </div>
      <div className="border-grey"/>
      <div className="opportunity-card-bottom">
        <div className="opportunity-card-infos">
          <span className="os-body1 info-label">TRI net cible
            <OsTooltip title={<Typography color="inherit">Le taux de rentabilité net annuel estimé, fourni à titre indicatif</Typography>}>
              <div className="tooltip-btn"><InfoOutlinedIcon /></div>
            </OsTooltip>
          </span>
          <span className="os-body1 info-value">{opportunity.actual_rate}</span>
        </div>
        <div className="opportunity-card-infos">
          <span className="os-body1 info-label">Horizon
            <OsTooltip title={<Typography color="inherit">Horizon d'investissement fourni à titre indicatif</Typography>}>
              <div className="tooltip-btn"><InfoOutlinedIcon /></div>
            </OsTooltip>
          </span>
          <span className="os-body1 info-value">{opportunity.horizon}</span>
        </div>
        <div className="opportunity-card-infos">
          <span className="os-body1 info-label">Investissement minimum</span>
          <span className="os-body1 info-value">{MoneyHelper.formatEuro(cgp !== null ? opportunity.cgp_minimum_investment : opportunity.minimum_investment, true, 0, 0)}</span>
        </div>
          {/*{cgp && <div className="os-flex gap-8" style={{marginTop: 8}}>
          {(cgp && opportunity.cgp_fees) && getBadge('verified', 'kyc_status', `${opportunity.is_os ? '0 à ' : ''} ${NumberHelper.formatPercentage(opportunity.cgp_fees)} Frais d'entrée`, 'info')}
          {(cgp && opportunity.cgp_recurring_fees) && getBadge('verified', 'kyc_status', `${NumberHelper.formatPercentage(opportunity.cgp_recurring_fees)} Commission de gestion`, 'info')}
        </div>}*/}
      </div>
      <div className={page === 'dashboard' ? `opportunity-card-btn opportunity-card-btn__dashboard` : `opportunity-card-btn`}>
        {renderButton()}
      </div>
      <OsModal title={`${opportunity.name}`} isShowing={showingPreview} hide={() => setShowingPreview(false)}>
        <div style={{marginBottom: 8}}>
          <p dangerouslySetInnerHTML={{__html: opportunity.content}} className="opportunity-card-content" />
          <div className="private-product-container">
            <div>
              <p className="os-subtitle2">⏱️{' '} Cette offre arrive bientôt</p>
              <p className="os-body2">L'offre, et ses informations, seront réservées à un nombre d'investisseurs limité.</p>
            </div>
            <div>
              <p className="os-subtitle2">⌛️{' '} Inscription sur liste d’attente</p>
              <p className="os-body2">Vous souhaitez y avoir accès ? Inscrivez-vous dès maintenant sur liste d'attente. Vous serez notifié lors de son ouverture, si une place vous est attribuée.</p>
            </div>
            <div>
              <p className="os-subtitle2">👌️{' '} Sans engagement</p>
              <p className="os-body2">L'inscription dans la liste d'attente ne vous engage pas à investir dans cette offre.</p>
            </div>
          </div>
        </div>
        <div className="os-flex gap-16 interest-card-btns">
          <OSButton onClick={() => setShowingPreview(false)} title="Retour" size="medium" variant="no_border" fit icon={<ArrowBackIcon />} />
          <OSButton onClick={() => createInterest()} title="Je m'inscris sur la liste d'attente" size="medium" variant="primary" fit submitting={submitting} icon={<AddAlertIcon />} />
        </div>
      </OsModal>
    </div>
  );
};

export default OpportunityCard;
