import React from "react";

import {ReactComponent as More} from '../icons/ic_more.svg';

import DashboardIcon from '@mui/icons-material/Dashboard';
import SpeedIcon from '@mui/icons-material/Speed';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const generalCgpLinks = [
  {
    title: 'Dashboard',
    link: '/cgp/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Gestion clients',
    link: '/cgp/clients',
    icon: <GroupOutlinedIcon />
  },
	{
		title: 'Statistiques',
		link: '/cgp/statistiques',
		icon: <SpeedIcon />
	},
];

const cabinetLinks = [
	{
		title: 'Dashboard',
		link: '/cgp/dashboard',
		icon: <DashboardIcon />
	},
	{
		title: 'Gestion clientèle',
		link: '/cgp/clients',
		icon: <GroupOutlinedIcon />
	},
	{
		title: 'Cabinet',
		link: '/cgp/cabinets/overview',
		icon: <AccountBalanceIcon />
	},
	{
		title: 'Statistiques',
		link: '/cgp/statistiques',
		icon: <SpeedIcon />
	},
];

const soonCgpLinks = [
]

const desktopCgpLinks = [
	{
		title: 'Toutes les offres',
		link: '/opportunites',
		icon: null
	},
];

// Conditionally add Openstone Prime only if environment is production
{/*if (process.env.NODE_ENV === 'production') {
	desktopCgpLinks.push({
		title: 'Openstone Prime',
		link: '/opportunites/alternatifs/openstone-prime',
		icon: null
	});
}*/}

const mobileCgpLinks = [
  {
    title: 'Dashboard',
    link: '/cgp/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Gestion clients',
    link: '/cgp/clients',
    icon: <GroupOutlinedIcon />
  },
  {
    title: 'Nos produits',
    link: '/opportunites',
    icon: <More />
  }
];


export { generalCgpLinks, desktopCgpLinks, soonCgpLinks, mobileCgpLinks, cabinetLinks };
