import React from "react";
import TableLoader from "../Loaders/Table/TableLoader";
import EmptyContent from "../empty-content/EmptyContent";
import {ReactComponent as NoData} from "../../assets/Illustrations/no-transactions.svg";
import MyTable from "../Table/myTable";

function renderTransactionsTable(loadingTransactions, transactions, type, product, page) {
	
  if (loadingTransactions) {
    return <TableLoader />
  } else if (!loadingTransactions && transactions?.length > 0) {
    return (
      <>
        <MyTable
          title="Transactions récentes"
          tableData={page !== "Full" ? transactions.slice(0, 6) : transactions}
          subheader=''
          tableLabels={[
            {id: 'title', label: 'Description'},
            {id: 'created_at', label: 'Date'},
            {id: 'amount', label: 'Montant'},
            {id: 'status', label: 'Statut'},
            {id: 'actions', label: 'Actions'},
          ]}
          cgp={type === 'cgp' && true}
          product={product}
        />
      </>
    )
  } else if (!loadingTransactions && transactions?.length === 0) {
    return (
      <>
        <EmptyContent
          text={type === 'cgp' ? "Pas de transactions pour cet investissement" : "Vous n'avez pas encore de transactions"}
          illustration={<NoData />}
          cta={{title: type === 'cgp' ? `Faire souscrire au produit ${product?.name}` : `J'effectue mon premier investissement ${product?.name}`, link: `/opportunites/${product?.category_slug}/${product?.slug}`, variant: 'primary-full', size: "small"}}
        />
      </>
    )
  }
}

export default renderTransactionsTable;
