import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import OsTooltip from "../../../components/Tooltips";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoneyHelper from "../../../Helpers/MoneyHelper";
import WalletIcon from '@mui/icons-material/Wallet';
import AddIcon from '@mui/icons-material/Add';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import ProductNework from "../../../config/Network/ProductNework.ts";

import ContactBlock from "../../../components/Contact";
import OpportunityCardLoader from "../../../components/Loaders/Opportunities/OpportunityCardLoader";
import LargeOpportunityCard from "../../../components/Product/OpportunityCard/large";
import OSButton from "../../../components/Button";

import Images from "../../../assets/images";

import './style.scss';
import OpportunitiesSlider from "../../../components/Sliders/Slider";
import BannerLoader from "../../../components/Loaders/Banner";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";


const CgpDashboard = ({cgpUser, workspace}) => {
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingOpportunities, setLoadingOpportunities] = useState(true);
  const [refresh, setRefresh] = useState(false);

  async function fetchData() {
    const apiProducts = await ProductNework.getProducts(null);
    setOpportunities(apiProducts);
    setTimeout(() => {
      setLoadingOpportunities(false);
    }, 200);
  }

  useEffect(() => {
    fetchData()
  }, []);
	
	useEffect(() => {
		cgpUser && setLoading(false);
	}, [cgpUser])

  useEffect(() => {
    refresh && fetchData()
  }, [refresh])
	
	const renderLargeOpportunity = () => {
		if (loading) {
			return (
				<div style={{marginBottom: 32}}>
					<div className="opportunity-loading-card" key="loader-opportunity-1">
						<OpportunityCardLoader />
					</div>
				</div>
			);
		} else if (!loading && opportunities.length > 0) {
			const openOpportunity = opportunities.find(opportunity => opportunity.position === 'top');
			if (openOpportunity) {
				return (
					<div style={{marginBottom: 32}}>
						<div className="dashboard-bottom-container">
							<div className="os-flex dashboard-slider-content">
								<div>
									<h4 className="opportunities-title opportunities-title-top os-h4">Openstone Prime</h4>
									<p className="opportunities-content">Pour la première fois, les meilleurs gérants immobiliers au monde réunis dans un même fonds</p>
								</div>
							</div>
							<LargeOpportunityCard category={openOpportunity} opportunity={openOpportunity} needRefresh={setRefresh} />
						</div>
					</div>
				)
			}
		}
		return null;
	}
	
	const renderOpportunities = () => {
    if (!loadingOpportunities && opportunities.length > 0) {
			const nonTopOpportunities = opportunities.filter(o => o.position !== 'top');
			const sortedOpportunities = nonTopOpportunities.sort((a, b) => b.is_os - a.is_os);
      return (
        <OpportunitiesSlider list={sortedOpportunities} needRefresh={setRefresh}/>
      )
    } else if (loadingOpportunities || opportunities.length === 0) {
      return <BannerLoader />
    }
  }
	
	const cardLinks = [
		{id: 1, title: "Clients créés", data: workspace === 'cgp' ? cgpUser?.clients_count : cgpUser?.cgp_company?.clients_count, content: "Clients créés sur Openstone", buttonText: "Créer mon premier client", buttonLink: "/cgp/clients", icon: <PeopleOutlineIcon />},
		{id: 2, title: "Clients investisseurs", data:  workspace === 'cgp' ? cgpUser?.active_clients_count : cgpUser?.cgp_company?.active_clients_count, content: "Clients ayant souscrit sur Openstone", buttonText: "Gérer ma clientèle", buttonLink: "/cgp/clients", icon: <WalletIcon />},
		{id: 3, title: "Volume sous gestion", data: parseFloat( workspace === 'cgp' ? cgpUser?.aum : cgpUser?.cgp_company?.aum), content: "Volume total sous gestion sur Openstone", buttonText: "Réaliser une souscription", buttonLink: "/cgp/clients", icon: <AccountBalanceIcon />},
	]

  return (
    <div className="dashboard-page">
      <Helmet>
        <title>Dashboard | Openstone</title>
      </Helmet>
      <div className="dashboard-container">
	      <div className="os-flex dashboard-container-title">
	        <h4 className="os-h4">Votre dashboard {workspace === 'cgp' ? 'conseiller' : 'cabinet'}</h4>
		      <div className="os-flex dashboard-top-container-btns">
			      <OSButton variant="no_border" link="/cgp/guides" title="Consulter les guides" fit size="large" />
			      <OSButton variant="primary" link="/cgp/clients" title="Gérer ma clientèle" fit size="large" />
		      </div>
	      </div>
        <div className="dashboard-top-container" style={{flexWrap: "wrap"}}>
					{workspace === 'cgp' ? <p className="os-subtitle2" style={{marginBottom: -12}}>Vos statistiques conseiller</p> : <p className="os-subtitle2" style={{marginBottom: -12}}>Statistiques du cabinet</p>}
          <div className="os-flex dashboard-cards">
            {cardLinks.map((cl, index) =>
              <div className="os-card os-card--bg-grey os-flex__column stats-card">
	              <div className="os-flex gap-8 card-icon-container">
		              <div className="os-flex round-icon">
		                {cl.icon}
		              </div>
		              <div className="os-flex card-icon-title">
		                <p>{cl.title}</p>
			              <OsTooltip title={<Typography color="inherit">{cl.content}</Typography>}>
				              <div className="tooltip-btnOpportunityCardInfos"><InfoOutlinedIcon /></div>
			              </OsTooltip>
		              </div>
	              </div>
	              {cl.data > 0 ? <h4 className="os-h4 data-card">{cl.id === 3 ? MoneyHelper.formatEuro(cl.data, true, 0, 0) : cl.data}</h4> : <OSButton variant="primary-full" size="small" title={cl.buttonText} fit link={cl.buttonLink} icon={<AddIcon />} />}
              </div>
            )}
          </div>
        </div>
				{renderLargeOpportunity()}
				{
        <div className="dashboard-bottom-container">
          <div className="os-flex dashboard-slider-content">
						<h4 className="opportunities-title opportunities-title-opportunities os-h4">Découvrez notre produits</h4>
            <div className="opportunities-slider-btn joyride1">
              <OSButton size="large" variant="no_border" title="Consulter toutes les opportunités" link="/opportunites" icon={<RemoveRedEyeIcon />} fit />
            </div>
          </div>
          {renderOpportunities()}
        </div>
        }
        <ContactBlock image={Images.charles}
                      name="L'équipe partenaires Openstone"
                      text="Notre objectif chez Openstone est de vous aider à trouver les meilleurs opportunités pour vos clients"
                      mail="partenaires@openstone.com" />
      </div>
    </div>
  )
}

export default CgpDashboard;
